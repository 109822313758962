import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import LazyImage from '../components/LazyImage';
import SiteMetadataWrapper from '../components/SiteMetadataWrapper';
import shapeSiteMetadata from '../shapes/siteMetadata';

const Post = ({ post }) => {
  const title = _.get(post, 'frontmatter.title') || _.get(post, 'fields.slug') || 'N/A';
  return (
    <section className="sk-section">
      <span className="sk-post__image">
        <LazyImage
          src="https://via.placeholder.com/1280x800"
          alt={ title }
        />
      </span>
      <h3>{ title }</h3>
      <div className="sk-post__date">
        { _.get(post, 'frontmatter.date') }
      </div>
      <p>{ post.excerpt }</p>
      <ul className="sk-post__actions">
        <li>
          <Link
            className="button alt"
            to={ _.get(post, 'fields.slug') }
          >
            Continue Reading
          </Link>
        </li>
      </ul>
    </section>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
};

class Posts extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    siteMetadata: shapeSiteMetadata.isRequired,
    data: PropTypes.object.isRequired,
  };

  componentDidMount () {
    window.document.body.className = 'posts';
  }

  render () {
    const {
      location,
      siteMetadata,
    } = this.props;

    const posts = _.chain(this.props)
      .get('data.posts.edges', [])
      .filter(post => !_.get(post, 'node.frontmatter.private', false))
      .value();

    return (
      <Layout
        location={ location }
        siteMetadata={ siteMetadata }
      >
        <div className="sk-container sk-top-lines-3" id="main">
          <header>
            <h2>Posts</h2>
            <p>All The Posts Since The Beginning of Time.</p>
          </header>
          {
            posts.map(post => (
              <Post
                key={ `posts::${_.get(post, 'node.fields.slug')}` }
                post={ post.node }
              />
            ))
          }
        </div>
      </Layout>
    );
  }
}

export default SiteMetadataWrapper(Posts);

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 500)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            private
          }
        }
      }
    }
  }
`;
