import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

const LazyImage = ({
  alt,
  src,
  width,
  height,
}) => {
  return (
    <LazyLoad
      width={ width }
      height={ height }
    >
      <img
        className="lazy"
        alt={ alt }
        src={ src }
        width={ width }
        height={ height }
      />
    </LazyLoad>
  );
};

LazyImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LazyImage.defaultProps = {
  alt: '',
  src: '',
  width: '',
  height: '',
};

export default LazyImage;
